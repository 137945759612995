.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #dd0223;
  line-height: 1.5;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #c4021f;
}
.button:active {
  background-color: #910117;
}
.desk {
  max-width: calc(100% - 100px);
}
#home {
  margin-left: 20px;
  transform: translateX(0);
  margin-top: 0;
  margin-bottom: 34px;
}
#head {
  margin-top: 60px;
}
.section--header {
  padding-top: 110px;
}
.navbar {
  padding-top: 35px;
}
.navbar:before {
  content: "";
  position: absolute;
  left: 0;
  top: 35px;
  min-width: 200px;
  width: calc(50% - 538px + 200px);
  height: 9px;
}
.mood-content {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
}
.section--footer .desk {
  flex-direction: row;
  align-items: flex-start;
}
.section--footer .vcard {
  max-width: 374px;
  margin-left: auto;
  margin-right: 8.33333333%;
}
.cb-goto-top {
  position: absolute;
  z-index: 10;
  right: 0;
  top: 0;
}
.cb-layout1 .area.main {
  width: 100%;
}
.cb-layout1 .area.main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout1 .area.main .cb-album .body,
.cb-layout1 .area.main .head,
.cb-layout1 .area.main .foot,
.cb-layout1 .area.main .part {
  margin-right: 1.44444444%;
  margin-left: 1.44444444%;
  width: 97.11111111%;
}
.cb-layout1 .area.main .tiny {
  width: 47.11111111%;
}
.cb-layout1 .area.main > .slim {
  width: 33.33333333%;
}
.cb-layout1 .area.main > .slim .head,
.cb-layout1 .area.main > .slim .foot,
.cb-layout1 .area.main > .slim .part {
  margin-right: 4.33333333%;
  margin-left: 4.33333333%;
  width: 91.33333333%;
}
.cb-layout1 .area.main > .slim.cb-album .body {
  margin-right: 4.33333333%;
  margin-left: 4.33333333%;
  width: 91.33333333%;
}
.cb-layout1 .area.main > .slim .tiny {
  width: 41.33333333%;
}
.cb-layout1 .area.side {
  width: 100%;
}
.cb-layout1 .area.side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout1 .area.side .cb-album .body,
.cb-layout1 .area.side .head,
.cb-layout1 .area.side .foot,
.cb-layout1 .area.side .part {
  margin-right: 5.2238806%;
  margin-left: 5.2238806%;
  width: 89.55223881%;
}
.cb-layout1 .area.side .tiny {
  width: 22.88557214%;
}
.cb-layout1 .area.side > .slim {
  width: 33.33333333%;
}
.cb-layout1 .area.side > .slim .head,
.cb-layout1 .area.side > .slim .foot,
.cb-layout1 .area.side > .slim .part {
  margin-right: 15.67164179%;
  margin-left: 15.67164179%;
  width: 68.65671642%;
}
.cb-layout1 .area.side > .slim.cb-album .body {
  margin-right: 15.67164179%;
  margin-left: 15.67164179%;
  width: 68.65671642%;
}
.cb-layout1 .area.side > .slim .tiny {
  width: 18.65671642%;
}
.cb-layout2 .area.main {
  width: 100%;
}
.cb-layout2 .area.main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .area.main .cb-album .body,
.cb-layout2 .area.main .head,
.cb-layout2 .area.main .foot,
.cb-layout2 .area.main .part {
  margin-right: 1.44444444%;
  margin-left: 1.44444444%;
  width: 97.11111111%;
}
.cb-layout2 .area.main .tiny {
  width: 47.11111111%;
}
.cb-layout2 .area.main > .slim {
  width: 50%;
}
.cb-layout2 .area.main > .slim .head,
.cb-layout2 .area.main > .slim .foot,
.cb-layout2 .area.main > .slim .part {
  margin-right: 2.88888889%;
  margin-left: 2.88888889%;
  width: 94.22222222%;
}
.cb-layout2 .area.main > .slim.cb-album .body {
  margin-right: 2.88888889%;
  margin-left: 2.88888889%;
  width: 94.22222222%;
}
.cb-layout2 .area.main > .slim .tiny {
  width: 94.22222222%;
}
.cb-layout2 .area.side {
  width: 100%;
}
.cb-layout2 .area.side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .area.side .cb-album .body,
.cb-layout2 .area.side .head,
.cb-layout2 .area.side .foot,
.cb-layout2 .area.side .part {
  margin-right: 1.17540687%;
  margin-left: 1.17540687%;
  width: 97.64918626%;
}
.cb-layout2 .area.side .tiny {
  width: 47.64918626%;
}
.cb-layout2 .area.side > .slim {
  width: 50%;
}
.cb-layout2 .area.side > .slim .head,
.cb-layout2 .area.side > .slim .foot,
.cb-layout2 .area.side > .slim .part {
  margin-right: 2.35081374%;
  margin-left: 2.35081374%;
  width: 95.29837251%;
}
.cb-layout2 .area.side > .slim.cb-album .body {
  margin-right: 2.35081374%;
  margin-left: 2.35081374%;
  width: 95.29837251%;
}
.cb-layout2 .area.side > .slim .tiny {
  width: 45.29837251%;
}
.area.farnorth {
  width: 22.45508982%;
  max-width: 200px;
}
.cb-layout1 .area.main {
  width: 102.97482838%;
  margin-left: -1.48741419%;
}
.cb-layout1 .area.main .unit.slim {
  width: 97.11111111%;
  margin-left: 1.44444444%;
  margin-right: 1.44444444%;
  padding-top: 17px;
  padding-bottom: 17px;
  column-count: 2;
  column-gap: 24px;
}
.cb-layout1 .area.main .unit.slim .part {
  float: unset;
  width: 100%;
  margin: 0;
}
.cb-layout1 .area.side {
  width: 111.66666667%;
  margin-left: -5.83333333%;
}
.cb-layout2 .area.main {
  width: 83.33333333%;
  margin-left: 8.33333333%;
}
.cb-layout2 .area.main .unit.seam.wide {
  width: 66.66666667%;
}
.cb-layout2 .area.main .unit.seam.wide .part {
  width: 95.66666667%;
  margin-left: 2.16666667%;
  margin-right: 2.16666667%;
}
.cb-layout2 .area.main .unit.seam.wide .part.tiny {
  width: 45.66666667%;
}
.cb-layout2 .area.main .unit.seam.slim {
  width: 33.33333333%;
}
.cb-layout2 .area.main .unit.seam.slim .part {
  width: 91.33333333%;
  margin-left: 4.33333333%;
  margin-right: 4.33333333%;
}
.cb-layout2 .area.main .unit.seam.slim .part.tiny {
  width: 41.33333333%;
}
.cb-layout2 .area.main .unit.flat {
  width: 30.44444444%;
  margin-left: 1.44444444%;
  margin-right: 1.44444444%;
}
.cb-layout2 .area.main .unit.flat .part {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
.cb-layout2 .area.side {
  width: 102.40740741%;
  margin-left: -1.2037037%;
}
/*# sourceMappingURL=./screen-medium.css.map */